<template>
  <div class="academy-calendar">
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <legend class="large-legend">
      <strong>{{ $t('timetable') }}</strong>
    </legend>

    <FullCalendar id="calendar" :options="calendarOptions" ref="fullCalendar">
      <template #eventContent="arg">
        <div>
          <strong v-if="arg.event.extendedProps.contents.length">
            {{ arg.event.extendedProps.contents[0].name }}
          </strong>
          <br />
          <div v-if="arg.event.extendedProps.timetable" class="pt-1 pl-1">
            {{ $time(arg.event.extendedProps.timetable.startAt) }} -
            {{ $time(arg.event.extendedProps.timetable.endAt) }}
          </div>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import de from '@fullcalendar/core/locales/de';
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';

import dayjs from 'dayjs';

export default {
  components: {
    FullCalendar,
    Breadcrumbs,
  },
  data() {
    return {
      calendarOptions: {
        locale: de,
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        events: [],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        slotMinTime: '05:00:00',
        slotMaxTime: '21:00:00',
        height: 'auto',
        eventContent: this.renderEventContent,
        datesSet: this.handleDatesSet,
      },
    };
  },
  computed: {
    ...mapGetters(['getTimetables']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('timetable') }];
    },
    calendarEvents() {
      return this.getTimetables.map((timetable) => ({
        id: timetable.id,
        start: new Date(timetable.startAt),
        end: new Date(timetable.endAt),
        extendedProps: {
          timetable,
          contents: timetable.contents,
        },
      }));
    },
  },
  methods: {
    ...mapActions(['fetchTimetables']),
    hasEmployees(event) {
      return event.employees && event.employees.length > 0;
    },
    renderEventContent(arg) {
      return {
        html: `
          <strong>${
            arg.event.extendedProps.contents[0]
              ? arg.event.extendedProps.contents[0].name
              : 'No Title'
          }</strong>
          <br>
          ${arg.timeText}
        `,
      };
    },
    async loadTimetables(start, end) {
      await this.fetchTimetables();
      this.updateCalendarEvents();
    },
    updateCalendarEvents() {
      this.calendarOptions = {
        ...this.calendarOptions,
        events: this.calendarEvents,
      };
    },
    handleDatesSet(arg) {
      const start = new Date(arg.startStr);
      const end = new Date(arg.endStr);
      this.loadTimetables(start, end);
    },
  },
  watch: {
    getTimetables: {
      handler() {
        this.updateCalendarEvents();
      },
      deep: true,
    },
  },
  async mounted() {
    const calendarApi = this.$refs.fullCalendar.getApi();
    const view = calendarApi.view;
    this.loadTimetables(view.activeStart.toISOString(), view.activeEnd.toISOString());
  },
};
</script>

<style scoped>
#calendar {
  width: 100%;
  margin: 0 auto;
}
.large-legend {
  font-size: 2em;
}
</style>
